<template>
  <v-container>
    <div class="box-shadow mb-0">
      <div class="faq">
        <h3 class="section__title">Haben Sie Fragen?</h3>
        <ul class="faq-block-wrap">
          <li class="faq-item" v-for="(item, index) of faqItems" :key="index">
            <div class="faq-item-block">
              <div class="faq-title" @click="toggleItem(index)">
                <span class="faq-marker epicon" :class="{'epicon-minus': isActive === index, 'epicon-plus': isActive !== index}"></span>
                {{ item.title }}

              </div>
              <transition name="slide">
                <div v-if="isActive === index">
                  <div class="faq-answer" v-html="item.content" />
                </div>
              </transition>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'FAQ',
    data() {
      return {
        isActive: 0,
        faqItems: [
          {
            title: 'Was ist ein Strompaket und welche Vorteile bringt mir die Nutzung?',
            content: 'Bei einem Strompaket kaufen Sie Strommengen auf Vorrat, ähnlich wie bei einem Prepaid-Guthaben.\n' +
                '      Schützen Sie sich vor Preiserhöhungen und profitieren Sie von keiner Vertragslaufzeit.'
          },
          {
            title: 'Was passiert, wenn das Strompaket aufgebraucht ist?',
            content: 'Wir informieren Sie rechtzeitig vor Ablauf Ihres Strompaketes, sofern Sie Ihren Zählerstand wie\n' +
                    '      vereinbart übermitteln. Wenn Ihr Strompaket aufgebraucht ist, haben Sie mehrere Möglichkeiten:\n' +
                    '      Entweder kaufen Sie ein neues Strompaket, schließen einen neuen Stromtarif ab oder falls Sie sich\n' +
                    '      für keine dieser Optionen entscheiden, beliefert Ihr örtlicher Grundversorger Sie mit Strom.' +
                    '<p class="mt-3">Bitte berücksichtigen Sie, dass wir Ihnen noch keine genauen Preise für Folgestrompakete' +
                    ' nennen können, da wir als Stromanbieter keinen Einfluss auf die Veränderung von Steuern und Abgaben haben.</p>'
          },
          {
            title: 'Wie kann ich Strompakete nachbestellen?',
            content: 'Sie haben folgende Möglichkeiten ein Strompaket nachzubestellen: Entweder über den Link <a href="https://www.eprimo.de/service/contract/meter-readings/add" target="_blank">https://www.eprimo.de/service/contract/meter-readings/add</a> oder über unsere Hotline 0800-204011093 .'
          },
          {
            title: 'Was passiert mit meinem bestehenden Stromvertrag?',
            content: ' Um von den Vorteilen der Strompakete profitieren zu können, beenden wir Ihren laufenden Vertrag\n' +
                    '      und rechnen diesen ab.'
          },
          {
            title: 'Haben Strompakete ein Verfallsdatum?',
            content: 'Nein, die Strompakete haben keine Laufzeiten.'
          },
          {
            id: 6,
            title: ' Welche Voraussetzungen benötige ich, um ein Strompaket zu nutzen?',
            content: ' Es bedarf keine Voraussetzungen. Sie müssen lediglich einen Zugang zu Ihrem Stromzähler haben,\n' +
                    '      um den Stand regelmäßig ablesen zu können.'
          },
          {
            title: 'Welche Zahlungsweisen sind möglich?',
            content: 'Sie können sich zwischen einer Einmalzahlung oder einem monatlichen Ratenkauf entscheiden. Die\n' +
                    '      Zahlungsart erfolgt entweder per Überweisung oder als Lastschrift.'
          },
          {
            title: 'Was ist der Vorteil einer Einmalzahlung gegenüber der Ratenzahlung?',
            content: 'Bei einer Einmalzahlung profitieren Sie von einem günstigeren Paketpreis. Sofern Sie den Betrag per\n' +
                    '      Ratenzahlung bezahlen möchten, zahlen Sie die Summe über die Anzahl der Monate ab, die der\n' +
                    '      angegebenen Reichweite des Strompakets entspricht. Einen Ratenzahlungsplan erhalten Sie\n' +
                    '      selbstverständlich in Ihrem Begrüßungsschreiben.'
          },
          {
            title: 'Wie ermittelt eprimo den Verbrauch, für die aktuellen Strompakete?',
            content: 'Um den Ablauf Ihres Strompaketes genau bestimmen zu können, benötigen wir regelmäßig Ihren\n' +
                    '      Zählerstand. Diesen können Sie unter <a href="https://www.eprimo.de/service" target="_blank">mein Eprimo</a> übermitteln.\n' +
                    '      Für die Zukunft planen wir eine automatische Zählerstandübermittlung durch einen digitalen Zähler.'
          },
          {
            title: 'Wann beginnt ein Strompaket?',
            content: 'Ab dem 1. des Folgemonats nach Bestellung beginnt Ihr Strompaket.'
          },
          {
            id: 11,
            title: 'An wen kann ich mich bei Fragen wenden?',
            content: 'Sie können sich rund um die Uhr per Mail an strompakete@eprimo.de wenden, oder unseren\n' +
                    '      Kundenservice von 8-18 Uhr unter der 0800-204011093 anrufen.'
          },
          {
            title: 'Wo kommt die grüne Energie her?',
            content: ' Um die Energiewende voranzutreiben, unterstützt eprimo den Ausbau regenerativer Energiequellen:\n' +
                    '      Mit klimaneutral erzeugtem Strom aus Wasserkraft setzen wir auf eine umweltfreundliche\n' +
                    '      Stromerzeugung, die bereits seit Jahrhunderten genutzt wird und zu den Klassikern der\n' +
                    '      Energiegewinnung zählt.\n' +
                    '      Wir können mehr bieten als nur attraktive Tarife und Konditionen: Dank unseres CO2-neutralen\n' +
                    '      Ökostroms tragen wir aktiv dazu bei, mit umweltfreundlicher Energie den ökologischen Fußabdruck\n' +
                    '      zu verringern.'
          },
          {
            title: 'Wie setzt sich der eprimo-Strom zusammen?',
            content: 'Wir unterstützen den schonenden Umgang mit wertvollen Ressourcen. Unsere Stromtarife enthalten daher bereits ' +
                '91,2% Strom aus erneuerbaren Energien. Damit Sie nachvollziehen können, aus welchen unterschiedlichen Energieträgern ' +
                'der eprimo-Strommix besteht, sorgen wir für mehr Transparenz:<br>' +
                '<div class="image-diagram"></div>' +
                '<ul class="faq-list">' +
                '<li>Kohle: 6,5%</li>' +
                '<li>Erneuerbare Energien gefördert nach der EEG: 60,3%</li>' +
                '<li>Sonstige erneuerbare Energien: 30,9%</li>' +
                '<li>Kernkraft: 1,9%</li>' +
                '<li>Erdgas: 0,3%</li>' +
                '<li class="enter-item">Sonstige fossile Energien: 0,1%</li>' +
                '<li>CO2-Emissionen: 67 g/kWh</li>' +
                '<li>Radioaktiver Abfall: 0,0001 g /kWh</li>' +
                '</ul>' +
                '(Stromkennzeichnung der eprimo GmbH, gemäß §42 Energiewirtschaftsgesetz, Stand der Information: 1. November 2020)<br><br>'
          },
        ]
      }
    },
    methods: {
      toggleItem: function (index) {
        this.isActive = this.isActive === index ? null : index;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .slide-enter-active {
    transition-duration: 0.35s;
    transition-timing-function: ease-in;
  }

  .slide-leave-active {
    transition-duration: 0.35s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  .slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
  }

  .slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
  }

  .faq {
    &-block-wrap {
      list-style: none;
      margin-top: 35px;
    }

    &-item:not(:last-of-type) {
      padding-bottom: 20px;
      border-bottom: 1px solid $grey-light;
      margin-bottom: 20px;
    }

    &-item:last-of-type {
      padding-bottom: 5px;
    }

    &-title {
      cursor: pointer;
      position: relative;
    }

    &-item-block {
      padding-left: 40px;
    }

    &-answer {
      padding: 22px 40px 0 0;
    }

    &-marker {
      position: absolute;
      left: -40px;
      top: -2px;
      color: $white;
      width: 24px;
      height: 24px;
      display: block;
      border-radius: 50%;
      background: $green;
      line-height: 1.2;

      &:before {
        margin-left: -1px;
      }
    }
  }
</style>
