<template>
  <div>
    <div v-if="loadingPDF" class="loading-overlay">
      <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
      ></v-progress-circular>
    </div>
    <GreenBlock class-name="green-section--absolute"/>
    <v-container class="container--margin">
      <div class="box-shadow">
        <h3 class="section__title" v-if="isNewOffer">Vielen Dank, wir haben Ihre Bestellung vorbereitet</h3>
        <h3 class="section__title" v-else>Hier ist Ihr persönliches Angebot:</h3>
        <div class="packages packages-top">
          <div class="package">
            <div class="package__box">
              <div class="package__discount" v-if="selectedPackage.Discount > 0 && !isInitialFirstPackageEmpty">
                <div>-{{ selectedPackage.Discount }}%</div>
              </div>
              <h1 class="package__title">{{ selectedPackage.Name + ' ' + selectedPackage.NameSize }}</h1>
              <ul class="list-checkmark">
                <li>Menge: {{ addDotToThousands(selectedPackage.kwhQuantity) }} kWh</li>
                <li>Reicht etwa: {{ selectedPackage.EstimatedDuration }} Monate</li>
                <li v-if="selectedPackage.Discount > 0 && !isInitialFirstPackageEmpty">Rabatt Paketgröße:
                  {{ selectedPackage.Discount }}%
                </li>
                <li v-else>Keine Mindestlaufzeit</li>
              </ul>
              <div class="package__price mt-0 mb-4">
                <p class="price">{{ formatPrice(selectedPackage.PriceMonthly) }} €</p>
                <div>Monatliche Rate bei Ratenkauf<span v-if="selectedPackage.Discount > 0 && !isInitialFirstPackageEmpty"> (bereits rabattiert)</span>
                </div>
              </div>
              <div class="package__bottom mb-2">
                <div>Einmalpreis für Paket: {{ formatPrice(selectedPackage.PriceOTP) }} €</div>
                <button class="btn-back" @click="$router.go(-1)">Paket ändern…</button>
              </div>
            </div>
          </div>
        </div>
        <form class="package-form" id="start-form">
          <h3 class="section__title mb-5">Kundendaten</h3>
          <div class="package-form__item">
            <div class="package-form__group">
              <label class="form-group__label">Anrede</label>
              <div class="form-group__output w-600">{{ userData.Anrede }}</div>
            </div>
          </div>
          <div class="package-form__item">
            <div class="package-form__group">
              <label class="form-group__label">Vorname</label>
              <div class="form-group__output w-600">{{ userData.Vorname }}</div>
            </div>
          </div>
          <div class="package-form__item">
            <div class="package-form__group">
              <label class="form-group__label">Nachname</label>
              <div class="form-group__output w-600">{{ userData.Nachname }}</div>
            </div>
          </div>
          <div class="package-form__item">
            <div class="package-form__group">
              <label class="form-group__label">Adresse</label>
              <div class="form-group__output w-600">{{ userData.Strasse + ' ' + userData.Hausnr }}
                {{ userData.Hausnr_Ergaenzung }}
              </div>
            </div>
          </div>
          <div class="package-form__item">
            <div class="package-form__group">
              <label class="form-group__label">Postleitzahl</label>
              <div class="form-group__output w-600">{{ userData.PLZ }}</div>
            </div>
          </div>
          <div class="package-form__item">
            <div class="package-form__group">
              <label class="form-group__label">Ort</label>
              <div class="form-group__output w-600">{{ userData.Ort }}</div>
            </div>
          </div>
          <div class="package-form__item mt-3">
            <div class="package-form__group">
              <label class="form-group__label input-label popover-label">E-mail*
              </label>
              <div class="form-group__output">
                <v-text-field
                    v-model="fields.email.value"
                    :class="{'v-valid':fields.email.valid}"
                    :error-messages="fields.email.error"
                    outlined
                ></v-text-field>
                <small v-if="!isNewOffer">Mit Angabe Ihrer E-Mail-Adresse richten wir Ihnen automatisch einen Zugang zum kostenlosen
                  Online-Kundenbereich ein. Hierüber können Sie sämtliche Korrespondenz mit eprimo führen. Die Nutzung
                  des Kundenkontos zur digitalen Kommunikation ist bei diesem reinen Online-Produkt zwingend. Der Zugang
                  muss durch Sie noch aktiviert werden. Wir informieren Sie über alle weiteren Schritte per
                  E-Mail.</small>
              </div>
            </div>
          </div>
          <div class="package-form__item mt-3" v-if="!isNewOffer">
            <div class="package-form__group">
              <label class="form-group__label input-label">Email*<br> wiederholen</label>
              <v-text-field
                  class="form-group__output"
                  :class="{'v-valid':fields.emailConfirm.valid}"
                  v-model="fields.emailConfirm.value"
                  :error-messages="fields.emailConfirm.error"
                  outlined
              ></v-text-field>
            </div>
          </div>
          <div class="package-form__item mt-3">
            <div class="package-form__group">
              <label class="form-group__label input-label">Telefonnummer*</label>
              <v-text-field
                  class="form-group__output"
                  :class="{'v-valid':fields.phone.valid}"
                  v-model="fields.phone.value"
                  :error-messages="fields.phone.error"
                  outlined
              ></v-text-field>
            </div>
          </div>
          <div class="package-form__item">
            <div class="package-form__group">
              <label class="form-group__label">Zählernummer</label>
              <div class="form-group__output w-600">{{ userData.MeterID }}</div>
            </div>
          </div>
          <div class="package-form__item mb-7">
            <div class="package-form__group">
              <label class="form-group__label">Kundennummer</label>
              <div class="form-group__output w-600">{{ userData.VK }}</div>
            </div>
          </div>
          <hr>
          <h3 class="section__title">Zahlungsdaten</h3>
          <div class="package-form__item mt-3">
            <div class="package-form__group">
              <label class="form-group__label input-label">Zahlung</label>
              <div class="form-group__output form-group__packet">
                <v-radio-group v-model="fields.payment">
                  <div class="package-form-item">
                    <v-radio
                        value="Einmalzahlung"
                    ></v-radio>
                    <div class="form-package package">
                      <div class="package__box">
                        <div class="package__discount" v-if="selectedPackage.DiscountOTP > 0">
                          <div>-{{ selectedPackage.DiscountOTP }}%</div>
                        </div>
                        <h1 class="package__title">Einmalzahlung</h1>
                        <div class="package__price mt-0 mb-4">
                          <p class="price">{{ formatPrice(selectedPackage.PriceOTP) }} €</p>
                          <div>Einmalpreis für Paket<br>
                            <span>(bereits rabbattiert, {{ selectedPackage.DiscountOTP }}% Skonto)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="package-form-item">
                    <v-radio
                        value="Monatliche"
                    ></v-radio>
                    <div class="form-package package">
                      <div class="package__box">
                        <h1 class="package__title">{{ selectedPackage.EstimatedDuration }} monatliche Raten</h1>
                        <div class="package__price mt-0 mb-4">
                          <p class="price">{{ formatPrice(selectedPackage.PriceMonthly) }} €<sup v-if="isNewOffer">2</sup><sup v-else>1</sup></p>
                          <div>
                            Gesamtpreis:
                            {{ formatPrice(selectedPackage.EstimatedDuration * selectedPackage.PriceMonthly) }} €<br>
                          </div>
                          <div v-if="isNewOffer">
                            Reicht etwa: {{ selectedPackage.EstimatedDuration }} Monate<sup>1</sup>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-radio-group>
                <div class="explanation-text mb-2" v-if="isNewOffer">
                  <p><sup>1</sup> Angaben sind geschätzt, basierend auf Ihren zurückliegenden Verbrauchsdaten gemäß Ihrer letzten Jahresrechnung.</p>
                  <p><sup>2</sup> Sollten Sie sich für eine Ratenzahlung entscheiden, wird Ihnen eine Ratenzahlungsvereinbarung mit einem Ratenzahlungsplan in Ihr mein eprimo Postfach übersandt, aus
                    dem Sie die jeweiligen Fälligkeiten entnehmen können. Die Gewährung der Ratenzahlung erfolgt zinsfrei über die von Ihnen gewählte Anzahl von Raten.<br> Weitere Details entnehmen
                    Sie
                    bitte den hier einsehbaren <a
                        :href="`${publicPath}eprimo_agb_Strompakete_komplett_210622.pdf`" target="_blank">Strompakete-AGB</a>.
                  </p>
                </div>
                <div class="explanation-text mb-2" v-else>
                  <p><sup>1</sup> Sollten Sie sich für eine Ratenzahlung entscheiden, wird Ihnen eine
                    Ratenzahlungsvereinbarung mit einem Ratenzahlungsplan in Ihr mein eprimo Postfach übersandt, aus dem
                    Sie die jeweiligen Fälligkeiten entnehmen können. Die Gewährung der Ratenzahlung erfolgt zinsfrei
                    über die Anzahl von Raten. Weitere Details entnehmen Sie bitte den hier einsehbaren <a
                        :href="`${publicPath}agb_Ergaenzende_Bedingungen_Strompakete_20200901.pdf`" target="_blank">Strompakete-AGB</a>.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="package-form__item mt-3">
            <div class="package-form__group">
              <label class="form-group__label input-label">Zahlung per</label>
              <div class="form-group__output">
                <div class="can-toggle can-toggle--size-large">
                  <input id="payment" type="checkbox" v-model="fields.paymentBy">
                  <label for="payment">
                    <div class="can-toggle__switch" data-checked="Überweisung" data-unchecked="Lastschrift"></div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!fields.paymentBy">
            <div class="package-form__item">
              <div class="package-form__group">
                <label class="form-group__label input-label">Kontoinhaber</label>
                <div class="form-group__output">
                  <v-text-field
                      v-model="fields.accountOwner.value"
                      :class="{'v-valid':fields.accountOwner.valid}"
                      :error-messages="fields.accountOwner.error"
                      outlined
                  ></v-text-field>
                </div>
              </div>
            </div>
            <div class="package-form__item">
              <div class="package-form__group">
                <label class="form-group__label input-label">IBAN</label>
                <div class="form-group__output">
                  <v-text-field
                      v-model="fields.iban.value"
                      :class="{'v-valid':fields.iban.valid}"
                      :error-messages="fields.iban.error"
                      outlined
                  ></v-text-field>
                </div>
              </div>
            </div>
            <div class="package-form__item">
              <div class="package-form__group">
                <label class="form-group__label"></label>
                <div class="form-group__output">
                  <v-checkbox
                      class="small-checkbox"
                      v-model="fields.debit.value"
                      :class="{'v-valid':fields.debit.valid}"
                      label="Ich ermächtige die eprimo GmbH (Gläubiger-Identifikationsnummer DE12ZZZ00000080651) widerruflich, fällige Beträge per Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von eprimo GmbH auf mein Konto gezogenen Lastschriften einzulösen.
Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen.
Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen."
                      :error-messages="fields.debit.error"
                  ></v-checkbox>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="package-form__legal-info mb-3">
            <div>
              <h3 class="section__title">Rechtliche Information</h3>
              <div class="package-form__links">
                <div v-if="isNewOffer">
                  <a href="https://www.eprimo.de/ueber-eprimo/agb/" target="_blank"><i
                      class="epicon epicon-arrow-edgy-right"/>AGB</a> &
                  <a :href="`${publicPath}eprimo_agb_Strompakete_komplett_210622.pdf`" target="_blank">Strompakete
                    AGB</a>
                </div>
                <div v-else>
                  <a href="https://www.eprimo.de/ueber-eprimo/agb/" target="_blank"><i
                      class="epicon epicon-arrow-edgy-right"/>AGB</a> &
                  <a :href="`${publicPath}agb_Ergaenzende_Bedingungen_Strompakete_20200901.pdf`" target="_blank">Strompakete AGB</a>
                </div>
                <div><a href="https://www.eprimo.de/ueber-eprimo/datenschutzerklaerung" target="_blank"><i
                    class="epicon epicon-arrow-edgy-right"/>Datenschutzinformation</a></div>
                <div><span @click.stop="dialogServise = true"><i
                    class="epicon epicon-arrow-edgy-right"/>Kundenservice</span>
                  <v-dialog
                      v-model="dialogServise"
                      scrollable
                  >
                    <v-card>
                      <v-card-title>
                        <div class="h1">Kundenbetreuung und Kundenbeschwerden</div>
                        <button class="epicon epicon-close" @click="dialogServise = false"></button>
                      </v-card-title>
                      <v-card-text>
                        <div class="modal-text" id="kundenbetreuung">
                          <h2 class="h1">Kundenbetreuung und Kundenbeschwerden</h2>
                          <p>Für evtl. Beanstandungen stehen Ihnen die u.a. Kontaktwege zur Verfügung. eprimo wird Ihre
                            Beanstandung binnen einer Frist von 4 Wochen beantworten.</p>
                          <p>Schriftlich: eprimo GmbH, Abteilung Kundenservice, Flughafenstr. 20, 63263 Neu-Isenburg</p>
                          <p>Telefonisch: Service-Hotline 0800/60 60 110 (kostenlos aus dem dt. Festnetz), für Anrufe
                            aus einem Mobilfunknetz: 069/80 88 12 34 (es entstehen die gemäß Ihrem Mobilfunkvertrag
                            üblichen Verbindungskosten für Anrufe ins dt. Festnetz)</p>
                          <p>Email: <a href="mailto:kundenservice@eprimo.de">kundenservice@eprimo.de</a></p>
                          <p>Sollte Ihrer Beanstandung nicht innerhalb der unter Ziffer 20.1 der Allgemeinen
                            Geschäftsbedingungen benannten Frist abgeholfen werden, können Sie sich unter den
                            Voraussetzungen des § 111b EnWG an die Schlichtungsstelle Energie e.V., Friedrichstraße 133,
                            10117 Berlin, <a href="http://www.schlichtungsstelle-energie.de" target="_blank">www.schlichtungsstelle-energie.de</a>,
                            <a href="mailto:info@schlichtungsstelle-energie.de">info@schlichtungsstelle-energie.de</a>,
                            030/27 57 240-0 wenden. eprimo ist zur Teilnahme an dem Streitbeilegungsverfahren gesetzlich
                            verpflichtet. Das Recht der Beteiligten, die Gerichte anzurufen oder ein anderes Verfahren
                            nach dem EnWG zu beantragen, bleibt unberührt. Durch ein etwaiges Schlichtungsverfahren wird
                            die Verjährung gemäß § 204 Abs. 1 Nr.4 BGB gehemmt.</p>
                        </div>
                        <button class="link-arrow" @click="printModal('kundenbetreuung')"><i
                            class="epicon epicon-arrow-edgy-right"></i>Drucken
                        </button>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </div>
                <div><span @click.stop="dialogRightWithdrawal = true"><i class="epicon epicon-arrow-edgy-right"/>Widerufsrecht</span>
                  <v-dialog
                      v-model="dialogRightWithdrawal"
                      scrollable
                  >
                    <v-card>
                      <v-card-title>
                        <div class="h1">Widerrufsbelehrung</div>
                        <button class="epicon epicon-close" @click="dialogRightWithdrawal = false"></button>
                      </v-card-title>
                      <v-card-text>
                        <div class="modal-text" id="widerrufsbelehrung">
                          <div class="h1">Widerrufsbelehrung</div>
                          <p>Widerrufsrecht</p>
                          <p>
                            Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu
                            widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses. Um
                            Ihr Widerrufsrecht auszuüben, müssen Sie uns (eprimo GmbH, Flughafenstraße 20, 63263
                            Neu-Isenburg, Telefon: 069 / 697670-0, Fax: 069 / 697670-111, E-Mail:
                            <a href="mailto:kundenservice@eprimo.de">kundenservice@eprimo.de</a>) mittels einer
                            eindeutigen Erklärung (z. B. ein mit der Post versandter Brief, Telefax oder E-Mail) über
                            Ihren
                            Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das
                            <a
                                href="https://content.eprimo.de/fileadmin/user_upload/AGB_PDFs/EPRIMO-Son-17001_Widerrufs_AS.pdf"
                                target="_blank"
                            >
                              Muster-Widerrufsformular
                            </a> verwenden, das jedoch nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht
                            es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der
                            Widerrufsfrist absenden.
                          </p>
                          <p>Widerrufsfolgen</p>
                          <p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen
                            erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die
                            sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene,
                            günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn
                            Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags
                            bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das
                            Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde
                            ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung
                            Entgelte berechnet.</p>
                        </div>
                        <button class="link-arrow" @click="printModal('widerrufsbelehrung')"><i
                            class="epicon epicon-arrow-edgy-right"></i>Drucken
                        </button>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </div>
                <div v-if="!isNewOffer"><span @click.stop="InformationContractPopup = true"><i class="epicon epicon-arrow-edgy-right"/>Informationen zum Vertragabschluss</span>
                  <v-dialog
                      v-model="InformationContractPopup"
                      scrollable
                  >
                    <v-card>
                      <v-card-title>
                        <div class="h1">Informationen im elektronischen Geschäftsverkehr</div>
                        <button class="epicon epicon-close" @click="InformationContractPopup = false"></button>
                      </v-card-title>
                      <v-card-text>
                        <div class="modal-text" id="informationen">
                          <div class="h1">Informationen im elektronischen Geschäftsverkehr</div>
                          <h2>1. Technische Schritte zum Vertragsschluss, Eingabefehler</h2>
                          <p>Nachdem Sie in unserem Tarifrechner einen günstigen Tarif ausgewählt haben (Button „Tarif
                            auswählen“), startet der schnelle und einfache Bestellprozess. Sie werden in weiteren
                            Schritten zur Eingabe Ihrer Daten und der erforderlichen Informationen für die Einleitung
                            des Wechselprozesses aufgefordert. Vor dem Absenden Ihrer Bestellung können Sie auf der
                            Bestellübersichtsseite Ihre Angaben prüfen und über die Links „ändern“ mögliche
                            Eingabefehler berichtigen.</p>
                          <p>Mit der Absendung Ihrer Bestellung über den Button „zahlungspflichtig bestellen“ geben Sie
                            eine verbindliche Vertragserklärung ab. Die Bestätigung des Eingangs der Bestellung, folgt
                            unmittelbar nach dem technisch einwandfreien Eingang Ihrer Bestellung. Diese
                            Eingangsbestätigung stellt noch keine Annahme Ihrer Vertragserklärung dar, sondern soll Sie
                            nur darüber informieren, dass Ihre Bestellung bei uns eingegangen ist und kann für
                            Archivierungszwecke verwendet werden. Das Zustandekommen eines verbindlichen Vertrages
                            bestimmt sich nach Ziff. 3 unserer
                            <a href="https://www.eprimo.de/ueber-eprimo/agb/">AGB</a>.</p>
                          <h2>2. Speicherung Vertragstext, Vertragssprache</h2>
                          <p>Unsere AGB, Datenschutzinformation und die weiteren Vertragsbestimmungen mit den Daten
                            Ihrer Bestellung, halten wir auf unseren Bestellseiten zum Abruf bereit. Es besteht dort die
                            Möglichkeit die Daten als PDF herunterzuladen oder über die Funktionen des von Ihnen
                            genutzten Browsers zu speichern.</p>
                          <p> Darüber hinaus lassen wir Ihnen die Vertragsbestimmungen noch einmal im Nachgang zu Ihrer
                            Bestellung zukommen, per Post oder sofern Sie sich dort anmelden, in unserem Kundenportal
                            „mein eprimo“.
                            Darüber hinaus wird der Vertragstext bei uns gespeichert, ist aber aus Sicherheitsgründen,
                            soweit Sie sich nicht für unser Kundenportal „mein eprimo“ registriert haben, nicht
                            unmittelbar von Ihnen abrufbar. Über unser Kundeportal „mein eprimo“ haben Sie mittels eines
                            passwortgeschützten direkten Zugangs die Möglichkeit, Ihre Daten zu verwalten. Die AGB
                            finden Sie in der jeweils gültigen Fassung auf unserer Webseite www.eprimo.de.
                            Verträge auf unserer Seite www.eprimo.de lassen sich derzeit nur in deutscher Sprache
                            schließen.</p>
                          <h2>3. Verhaltenskodizes der eprimo GmbH</h2>
                          <p>Die eprimo GmbH, als Teil der innogy SE, diese wiederum Teil der E.ON SE und der damit
                            verbundenen Unternehmen, unterwirft sich dem Verhaltenskodex des E.ON Konzerns. Diesen Kodex
                            finden Sie im Internet unter: www.eon.com/verhaltenskodex.</p>
                        </div>
                        <button class="link-arrow" @click="printModal('informationen')"><i
                            class="epicon epicon-arrow-edgy-right"></i>Drucken
                        </button>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </div>
              </div>
              <div class="fz-12 ml-2" v-if="isNewOffer">
                Wenn Sie sich dazu entscheiden, dieses Angebot zu nutzen verpflichten Sie sich, monatliche Zählerstände an eprimo zu übermitteln. Eine Erinnerung übersenden wir an Ihre oben genannte
                E-Mail-Adresse. Falls Sie einen Smartmeter haben, entfällt die Verpflichtung zur monatlichen Meldung an eprimo.
              </div>
              <div class="fz-12 ml-2" v-else>
                Wenn Sie sich dazu entscheiden, dieses Angebot zu nutzen verpflichten Sie sich, monatliche
                Zählerstände an eprimo zu übermitteln. Eine Erinnerung übersenden wir an Ihre oben
                genannte E-Mail-Adresse.
              </div>
            </div>
            <div class="package-form-box">
              <h3>Haben Sie Fragen?</h3>
              <div class="epicon epicon-service"></div>
              <div class="w-700 mb-2">Rufen Sie uns an:<br>
                0800-204011093
              </div>
              <div class="fz-12">Diese Rufnummer ist aus dem deutschen Festnetz kostenlos.
                Unsere Service-Zeiten sind montags – freitags von 9.00 – 18.00 Uhr.
              </div>
            </div>
          </div>
          <v-checkbox
              class="mt-2 ml-2 checkbox--margin"
              v-model="fields.optin.value"
              :class="{'v-valid':fields.optin.valid}"
              :error-messages="fields.optin.error"
          >
            <div slot='label' v-if="isNewOffer">Ich habe die AGB und Ergänzende AGB der Strompakete zur Kenntnis genommen und stimme diesen zu. <br>Ebenfalls habe ich die Widerrufsbelehrung und die
              Hinweise auf Beschwerdemöglichkeiten zur Kenntnis genommen.
            </div>
            <div slot='label' v-else>Ich habe die AGB und Strompakete-AGB zur Kenntnis genommen und stimme diesen zu. <br> Ebenfalls habe ich die Widerrufsbelehrung und die Hinweise auf
              Beschwerdemöglichkeiten zur Kenntnis genommen.
            </div>
          </v-checkbox>
          <v-checkbox
              class="mt-2 ml-2 checkbox--margin"
              v-model="fields.optin2.value"
              :class="{'v-valid':fields.optin2.valid}"
              :error-messages="fields.optin2.error"
          >
            <div slot='label'>Ich habe die für mich und in meinem Namen erzeugte, <span @click.stop><v-btn class="download-btn" @click="validate('downloadPDF')"> schriftliche Bestellung</v-btn></span>
              des gewählten Produkts<br> zur Kenntnis genommen und heruntergeladen.
            </div>
          </v-checkbox>
          <div v-if="optin2validate" class="v-input--selection-controls ml-2 pdf-dowload-error">
            <div class="v-messages error--text">Die PDF-Erzeugung erfordert alle Felder des obigen Formulars. Bitte füllen Sie alle benötigten Felder korrekt aus und versuchen Sie die PDF-Erzeugung
              erneut.<a href="#" v-scroll-to="'#start-form'"> Zum Formular</a></div>
          </div>
          <div class="text-center">
            <v-btn :class="{ loading: isSubmit }" class=" submit-btn btn mb-3" @click="validate('submit')" :disabled="loadingPDF">Jetzt kostenpflichtige Bestellung absenden</v-btn>
          </div>
        </form>
      </div>
    </v-container>
    <FAQ/>
  </div>
</template>

<script>
import FAQ from '@/components/FAQ.vue';
import GreenBlock from '@/components/GreenBlock';

export default {
  components: {
    FAQ,
    GreenBlock
  },
  props: ['userData', 'selectedPackage', 'lastPackage', 'isInitialFirstPackageEmpty', 'isNewOffer', 'isSmartMeterOrdered'],
  data() {
    return {
      isSubmit: false,
      publicPath: process.env.BASE_URL,
      dialogServise: false,
      dialogRightWithdrawal: false,
      InformationContractPopup: false,
      optin2validate: false,
      loadingPDF: false,
      fields: {
        phone: {
          value: '',
          error: '',
          valid: false,
          visible: true
        },
        email: {
          value: '',
          error: '',
          valid: false,
          visible: true
        },
        emailConfirm: {
          value: '',
          error: '',
          valid: false,
          visible: true
        },
        payment: 'Einmalzahlung',
        paymentBy: false,
        iban: {
          value: '',
          error: '',
          valid: false,
          visible: true
        },
        accountOwner: {
          value: '',
          error: '',
          valid: false,
          visible: true
        },
        debit: {
          value: false,
          error: '',
          valid: false,
          visible: true,
          type: 'checkbox'
        },
        optin: {
          value: false,
          error: '',
          valid: false,
          visible: true,
          type: 'checkbox'
        },
        optin2: {
          value: false,
          error: '',
          valid: false,
          visible: true,
          type: 'checkbox'
        }
      },
      validateFields: [
        'email',
        'emailConfirm',
        'phone',
        'accountOwner',
        'iban',
        'debit'
      ]
    }
  },
  watch: {
    'fields.paymentBy'(val) {
      this.fields.accountOwner.visible = !val;
      this.fields.iban.visible = !val;
      this.fields.debit.visible = !val;
    }
  },
  mounted() {
    if (typeof this.selectedPackage.PriceMonthly === 'string') {
      this.selectedPackage.PriceMonthly = (this.selectedPackage.PriceMonthly.replace(',', '.'));
    }
  },
  methods: {
    clearErrorMessages() {
      this.validateFields.forEach(field => this.fields[field].error = '');
    },
    validate(val) {
      const body = {};
      if (val === 'submit' && !this.validateFields.includes('optin2')) {
        this.validateFields.push('optin2')
        this.validateFields.push('optin')
        this.optin2validate = false
      } else if (val === 'downloadPDF') {
        this.loadingPDF = true
        let index = this.validateFields.indexOf('optin2');
        if (index !== -1) {
          this.validateFields.splice(index, 1);
        }
        index = this.validateFields.indexOf('optin');
        if (index !== -1) {
          this.validateFields.splice(index, 1);
        }
      }

      if (this.isNewOffer) {
        this.fields['emailConfirm'].visible = false
      }

      this.validateFields.forEach(f => {
            if (this.fields[f].visible) {
              if (f === 'emailConfirm') {
                body[f] = this.fields[f].value + '&' + this.fields.email.value;
              } else {
                body[f] = this.fields[f].value;
              }
            }
          }
      );

      this.sendRequest(process.env.VUE_APP_BACKEND_BASE_URL + 'validate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
          .then(res => res.json())
          .then(json => {
            this.clearErrorMessages();
            if (Object.keys(json).length !== 0 || json.length) {
              for (let prop in json) {
                this.fields[prop].valid = false
                this.fields[prop].error = json[prop][0];
              }
              this.validateFields.forEach(item => {
                    if (this.fields[item].error === '') {
                      this.fields[item].valid = true
                      if (this.fields[item].type === 'checkbox') {
                        this.fields.emailConfirm.valid = true
                      }
                    }
                    if (this.fields.email.valid === false) {
                      this.fields.emailConfirm.valid = false
                    }
                  }
              );
              if (val === 'downloadPDF') {
                this.optin2validate = true
                this.fields.optin.error = ''
                this.fields.optin2.error = ''
                this.loadingPDF = false
              }
            } else {
              if (val === 'submit') {
                this.submit();
              } else if (val === 'downloadPDF') {
                this.downloadPDF();
              }
            }
          });
    },
    downloadPDF: function() {
      this.optin2validate = false
      let offer = 'initial-package-order';
      if (this.isNewOffer) {
        offer = 'new-offer-order'
      }

      let totalPrice = this.formatPrice(this.selectedPackage.EstimatedDuration * this.selectedPackage.PriceMonthly)

      let paymentInfo = ''
      if (!this.fields.paymentBy) {
        paymentInfo = '&account_owner=' + this.fields.accountOwner.value + '&iban=' + this.fields.iban.value;
      } else paymentInfo = ''

      let href = 'pdf/' + this.$route.params.uuid + '/' + this.selectedPackage.Id + '/' + offer +
          '?email=' + encodeURIComponent(this.fields.email.value) +
          '&phone=' + this.fields.phone.value +
          '&package_size=' + this.selectedPackage.NameSize +
          '&two_package=' + this.isInitialFirstPackageEmpty +
          '&payment=' + this.fields.payment +
          '&total_price=' + totalPrice +
          '&payment_by=' + this.fields.paymentBy +
          paymentInfo;

      let comp = this
      var outputFileName = 'Strompaket_Bestelldetails.pdf'
      var req = new XMLHttpRequest();
      req.responseType = "blob";
      req.open("POST", process.env.VUE_APP_BACKEND_BASE_URL + href, true);
      req.setRequestHeader('Content-Type', 'application/json');
      req.onreadystatechange = function() {
        if (req.readyState === 4 && req.status === 200) {
          if (typeof window.chrome !== 'undefined') {
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(req.response);
            link.download = outputFileName;
            link.click();
            comp.loadingPDF = false
          } else if (typeof window.navigator.msSaveBlob !== 'undefined') {
            var blob = new Blob([req.response], {type: 'application/pdf'});
            window.navigator.msSaveBlob(blob, outputFileName);
            comp.loadingPDF = false
          } else {
            var file = new File([req.response], outputFileName, {type: 'application/force-download'});
            window.open(URL.createObjectURL(file));
            comp.loadingPDF = false
          }
        }
      };
      req.send(JSON.stringify([]));
    },
    submit: function() {
      let offer = 'initial-package-order';
      if (this.isNewOffer) {
        offer = 'new-offer-order'
      }
      let totalPrice = this.formatPrice(this.selectedPackage.EstimatedDuration * this.selectedPackage.PriceMonthly)
      let paymentInfo = ''
      if (!this.fields.paymentBy) {
        paymentInfo = '&account_owner=' + this.fields.accountOwner.value + '&iban=' + this.fields.iban.value;
      } else paymentInfo = ''

      let href = 'pdf/' + this.$route.params.uuid + '/' + this.selectedPackage.Id + '/' + offer +
          '?email=' + encodeURIComponent(this.fields.email.value) +
          '&phone=' + this.fields.phone.value +
          '&package_size=' + this.selectedPackage.NameSize +
          '&two_package=' + this.isInitialFirstPackageEmpty +
          '&payment=' + this.fields.payment +
          '&total_price=' + totalPrice +
          '&payment_by=' + this.fields.paymentBy +
          paymentInfo;

      const body = {
        user: {
          ...this.userData,
          Email: this.fields.email.value,
          Telefonnummer: this.fields.phone.value,
          Zahlung: this.fields.payment,
          Zahlung_Per: this.fields.paymentBy ? 'Überweisung' : 'Lastschrift',
          Rechtliche_Zustimmung: this.fields.optin.value,
          Smart_Meter_Request: 0
        },
        package: this.selectedPackage,
        pdf_url: href
      };

      if (!this.fields.paymentBy) {
        body.user.Kontoinhaber = this.fields.accountOwner.value;
        body.user.IBAN = this.fields.iban.value;
        body.user.Lastschriftmandat = this.fields.debit.value;
      }
      this.isSubmit = true;
      this.sendRequest(process.env.VUE_APP_BACKEND_BASE_URL + 'data/submit/' + this.$route.params.uuid, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
          .then(res => res.json())
          .then(json => {
            if (json.success) {
              this.$cookies.set('token', json.token);
              this.isSubmit = false;
              if (this.isSmartMeterOrdered) {
                this.$cookies.set('token', '');
                this.$router.push('/danke-new-offer');
              } else {
                if (this.isNewOffer) {
                  this.$router.push('/danke-new-offer');
                } else {
                  this.$router.push({
                    name: 'thankYou',
                    params: {firstName: this.fields.firstName}
                  });
                }
              }
            }
          });
    },
    formatPrice(value) {
      let val = value.toString().replace(',', '.')
      val = parseFloat(val).toFixed(2).replace('.', ',')
      return this.addDotToThousands(val);
    },
    printModal(id) {
      this.printElement(document.getElementById(id));
    },
    addDotToThousands(val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    printElement(elem) {
      var domClone = elem.cloneNode(true);
      var $printSection = document.getElementById('printSection');

      if (!$printSection) {
        $printSection = document.createElement('div');
        $printSection.id = 'printSection';
        document.body.appendChild($printSection);
      }

      $printSection.innerHTML = '';
      $printSection.appendChild(domClone);
      window.print();
    }
  }
}
</script>

<style lang="scss" scoped>
.epicon-service {
  font-size: 50px;
  color: $green;
}

.v-btn--contained:after {
  box-shadow: none !important;
}

.checkbox--margin .download-btn.v-btn.theme--light {
  font-size: 12px;
  line-height: 1.3;
  letter-spacing: unset;
  color: #7b7e88;
  background-color: unset;
  text-transform: none;
  padding: 0;
  box-shadow: none;
  text-decoration: underline;
  vertical-align: unset;
}

.submit-btn.btn:not(.popover-email) {
  position: relative;
}

.loading:after {
  content: "";
  position: absolute;
  height: 200px;
  width: 500px;
  top: 0;
  bottom: 0;
  left: 0;
  background: repeating-linear-gradient(-60deg, hsla(0, 0%, 100%, .3) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .3) 0, hsla(0, 0%, 100%, .3) 75%, transparent 0, transparent);
  background-size: 56px 56px;
  background-position: 0 0;
  -webkit-animation: a 4s infinite linear forwards;
  animation: a 4s infinite linear forwards;
}

@keyframes a {
  0% {
    background-position: 0 0
  }
  100% {
    background-position: 100% 0
  }
}

@-webkit-keyframes a {
  0% {
    background-position: 0 0
  }
  100% {
    background-position: 100% 0
  }
}

.popover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);

  &-email {
    margin-right: -22px;
    min-width: auto;
    display: inline-block;
    vertical-align: middle;
    padding: 0;
    height: 22px;
    color: $grey-light-3;
    border: none;
    background: transparent;

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    &:focus,
    &:active,
    &:hover {
      color: $grey-light-3;
      background: transparent;
      border: none;
      box-shadow: none;
    }

    .epicon {
      font-size: 22px;
    }
  }
}

.link-arrow {
  color: $blue;
  font-size: 13px;

  .epicon {
    font-size: 13px;
    vertical-align: initial;
    margin-right: 0;
    margin-left: -6px;
  }
}

.container--margin {
  margin-top: 15px;
}

.v-btn:not(.v-btn--round).v-size--default {
  padding: 6px 30px;
}

.v-btn.v-size--default {
  font-size: 18px;
}

.epicon-arrow-edgy-right {
  vertical-align: text-bottom;
  margin-right: 10px;
}

.fz-12 {
  font-size: 12px;
}

.packages {
  &-top {
    .package__title {
      padding: 9px 0;
    }
  }
}

.package {
  &__price {
    min-height: auto;
  }

  &__discount {
    right: -5px;
    top: -25px;
  }

  &-form {
    margin-top: 20px;

    &__links {
      margin-bottom: 20px;

      > div {
        margin-bottom: 7px;
        color: $blue;

        span {
          cursor: pointer;
          color: $blue;
        }
      }
    }

    &-item {
      position: relative;
    }

    &-box {
      border-radius: 4px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
      padding: 20px 30px;
      text-align: center;
      margin-right: 5px;

      h3 {
        color: $green;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 6px;
      }
    }
  }
}

.v-btn:not(.v-btn--round).v-size--default {
  height: auto;
}

.w-600 {
  font-weight: 600;
}

.w-700 {
  font-weight: 700;
}

.btn-back {
  color: $blue;
}

.can-toggle {
  position: relative;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  input[type=checkbox] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    &:checked ~ label .can-toggle__switch:before {
      content: attr(data-unchecked);
      left: 1px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:checked ~ label .can-toggle__switch:after {
      content: attr(data-checked);
    }

    &:checked ~ label .can-toggle__switch:after {
      transform: translate3d(100%, 0, 0);
    }
  }

  input[type=checkbox][disabled] ~ label {
    color: rgba(119, 119, 119, 0.5);
    pointer-events: none;

    .can-toggle__switch {
      opacity: 0.4;
      background-color: #70c767;

      &:before {
        content: attr(data-unchecked);
        left: 0;
      }

      &:after {
        content: attr(data-checked);
        color: #4fb743;
      }
    }
  }

  label {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;

    .can-toggle__label-text {
      flex: 1;
      padding-left: 32px;
    }

    .can-toggle__switch {
      height: 40px;
      flex: 0 0 134px;
      border-radius: 4px;
      position: relative;
      transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
      background: $white;

      &:before {
        content: attr(data-checked);
        position: absolute;
        top: 0;
        text-align: center;
        color: rgba(255, 255, 255, 0.5);
        left: 67px;
        font-size: 12px;
        line-height: 38px;
        width: 67px;
        padding: 0 12px;
      }

      &:after {
        content: attr(data-unchecked);
        position: absolute;
        z-index: 5;
        text-align: center;
        background: $green;
        transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
        color: $white;
      }
    }
  }

  &.can-toggle--size-large input[type=checkbox] {
    &:checked ~ label .can-toggle__switch:after {
      transform: translate3d(100%, 0, 0);
    }
  }

  &.can-toggle--size-large label {
    font-size: 14px;

    .can-toggle__switch {
      flex: 0 0 100%;
      border-radius: 4px;
      background-color: $green;
      overflow: hidden;
      font-weight: 600;
      margin-bottom: 5px;

      &:before {
        right: 1px;
        left: auto;
        top: 1px;
        font-size: 14px;
        line-height: 38px;
        width: 50%;
        padding: 0 12px;
        background: $white;
        color: $grey;
        border-radius: 4px;
      }

      &:after {
        top: 1px;
        left: 1px;
        border-radius: 2px;
        width: 50%;
        line-height: 38px;
      }
    }
  }
}

@media (max-width: 768px) {
  .btn-back {
    float: right;
  }

  .package {
    &__bottom {
      overflow: hidden;
    }

    &-form {
      &-item:not(:last-of-type) {
        margin-bottom: 30px;
      }

      &__group {
        margin-bottom: 12px;
      }

      &-box {
        margin-top: 30px;
      }
    }
  }

  .checkbox--margin {
    margin-right: 19px;
  }
}

@media (min-width: 768px) {
  .container--margin {
    margin-top: 50px;
  }

  .checkbox--margin {
    margin-right: 80px;
  }

  .list-checkmark {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 10px;
    margin-bottom: 18px;

    li {
      margin: 0 10px 0;
    }
  }

  .package {
    &__bottom {
      position: relative;
      padding: 0 100px;
    }

    &-form {
      &__group {
        margin-bottom: 7px;
        position: relative;
        display: flex;
        align-items: flex-start;
      }

      &__legal-info {
        display: flex;
      }

      &-box {
        min-width: 285px;
        max-width: 285px;
        margin-left: 40px;
      }
    }
  }

  .btn-back {
    position: absolute;
    right: 10px;
    top: 0;
  }

  .package-form-item {
    width: 50%;
    max-width: 300px;
    margin: 0 17px;

    .package {
      height: 100%;

      &__box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}
</style>

<style lang="scss">
.form {
  &-group {
    &__packet {
      .v-icon {
        display: none;
      }
    }

    &__output {
      small {
        font-size: 11px;
        display: block;
      }
    }
  }

  &-package {
    .package__price {
      min-height: 75px;
    }
  }
}

.package-form {
  .v-text-field input {
    color: $grey;
    max-height: initial;
    line-height: 22px;
    padding: 8px 20px;
    caret-color: $grey;
  }
}

.v-text-field__details {
  display: none;
}

.v-messages__message {
  line-height: 16px;
}

.v-input {
  > .v-input__control {
    > .v-input__slot {
      min-height: 40px;
      margin-bottom: 3px;
      padding: 0 !important;
    }
  }

  fieldset {
    border-color: $grey-light-2;
  }

  &.v-input--is-focused {
    fieldset {
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(180, 218, 4, .5);
      border: 1px solid $green-1;
      outline: none;
    }

    &.error--text {
      fieldset {
        box-shadow: 0 0 0 0.2rem rgba(254, 188, 2, .25);
      }
    }
  }

  .v-input__control .v-text-field__slot,
  .v-input__control .v-label {
    position: relative;

    &:after {
      font-family: epicons;
      position: absolute;
      right: -40px;
      left: auto;
      font-size: 18px;
      top: 50%;
      transform: translateY(-50%);
      border: none;
      width: initial;
    }
  }

  &.error--text {
    fieldset {
      border: 1px solid $orange;
    }

    .v-text-field__details {
      display: block;
      margin-bottom: 12px;
    }

    .v-text-field__slot,
    .v-label {
      &:after {
        content: "";
        display: block;
        color: $orange;
      }
    }

    .error--text {
      color: $orange !important;
      caret-color: $grey !important;
      font-size: 13px;
    }
  }

  &.v-valid {
    .v-text-field__slot,
    .v-label {
      &:after {
        content: "";
        display: block;
        color: $green;
      }
    }
  }
}

.v-input {
  font-size: 14px;
}

.v-input--selection-controls {
  .v-input__slot > .v-label {
    font-size: 12px;
    line-height: 1.3;
    color: $grey;

    &.error--text {
      color: $grey !important;
    }
  }

  .v-messages.error--text {
    color: $orange !important;
    margin-left: 30px;
    margin-bottom: 12px;
  }
}

.small-checkbox {
  padding-top: 10px;
}

.v-input--checkbox {
  div.v-input--selection-controls {
    &__input {
      margin-top: 5px;
      margin-right: 12px;
      width: 18px;
      height: 18px;
    }
  }

  .v-input__slot {
    align-items: flex-start;
  }

  .mdi-checkbox-blank-outline::before {
    content: '';
    width: 18px;
    height: 18px;
    border: 1px solid $grey-light-2;
    border-radius: 4px;
  }

  .mdi-checkbox-blank-outline::after {
    display: none;
  }

  .v-input--selection-controls__ripple {
    display: none;
  }

  .mdi-checkbox-marked {
    &::before {
      content: '';
      border: 1px solid #ccc;
      width: 18px;
      border-radius: 4px;
      height: 18px;
    }

    &:after {
      content: "";
      font-family: 'epicons';
      position: absolute;
      left: 3px;
      top: 3px;
      font-size: 12px;
      color: #73c72b;
      border-radius: 0;
      transform: none;
      background-color: transparent;
      opacity: 1;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      transition: none;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}

.v-radio {
  ~ .package {
    .package__title {
      color: $grey;
      margin: 0 -5px;
    }
  }

  &.v-item--active {
    ~ .package {
      .package__box {
        box-shadow: 0 0 10px rgba(0, 0, 0, .27);
      }

      .package__price {
        background-color: $green;
      }

      .package__title {
        color: $green;
      }
    }
  }

  .v-input--selection-controls__input {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;

    input[role=radio] {
      max-height: initial;
      z-index: 1;
    }
  }
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0;
}

.v-input--selection-controls {
  margin: 0;

  &__ripple {
    display: none;
  }
}

.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 0;
}

@media screen {
  #printSection {
    display: none;
  }

  .modal-text {
    .h1 {
      display: none;
    }
  }
}

@media print {
  body * {
    visibility: hidden;
    height: 0;
    color: #000 !important;
    text-decoration: none !important;
  }
  #printSection, #printSection * {
    visibility: visible;
    height: auto;
  }

  #printSection {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    margin: 35px 0;
    margin-right: 50px;

    .h1 {
      display: block;
      font-size: 18px;
      margin-bottom: 12px;
    }

    h2 {
      font-size: 14px;
      margin-bottom: 12px;
    }

    p {
      font-size: 9px;
      margin-bottom: 12px;
    }
  }
}

@media (min-width: 768px) {
  .v-input--radio-group--column .v-input--radio-group__input {
    flex-direction: row;
    margin: 0 -17px;
  }

  .form-group {
    &__output {
      width: 72.5%;
    }

    &__label {
      width: 28.5%;
      text-align: right;
      padding-right: 35px;

      &.input-label {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        word-break: break-word;
        min-height: 40px;
        flex-direction: column;

        &.popover-label {
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
        }
      }
    }
  }

  .v-text-field--outlined > .v-input__control > .v-input__slot,
  .can-toggle,
  .v-text-field__details,
  .small-checkbox,
  .form-group__output small {
    max-width: 420px;
  }
}

@media (max-width: 767px) {
  .form {
    &__output {
      width: 100%;
    }

    &-group__label {
      display: block;
    }
  }

  .package-form__item {
    padding-right: 20px;
  }

  .v-input {
    &.error--text,
    &.v-valid {
      .v-text-field__slot,
      .v-label {
        &:after {
          right: -25px;
        }
      }
    }
  }
}


.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(3px) brightness(1.001);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
